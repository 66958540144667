export default (urlString) => {
    try {
        const url = new URL(urlString);
        if (url.href.startsWith("https://www.youtube.com/embed/")
            || (url.hostname === "youtu.be")) {
            return url.href.split("/").pop();
        } else {
            const searchParams = new URLSearchParams(url.search);
            return searchParams.get("v");
        }
    } catch (err) {
        return null;
    }
};