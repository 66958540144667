<template>
  <div class="thumbnail-library">
    <h3>Click the image to view original size version in new tab</h3>
    <div
      class="thumbnail-library__item"
      v-for="imageUrl in imageUrls"
      :key="imageUrl"
    >
      <a :href="imageUrl" title="Open in new tab" target="_blank" rel="noopener">
        <img :src="imageUrl" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Library",
  props: {
    videoId: String,
  },
  computed: {
    imageUrls: function () {
      return [
        `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`,
        `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`,
        `https://img.youtube.com/vi/${this.videoId}/mqdefault.jpg`,

        `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
        `https://img.youtube.com/vi/${this.videoId}/1.jpg`,
        `https://img.youtube.com/vi/${this.videoId}/2.jpg`,
        `https://img.youtube.com/vi/${this.videoId}/3.jpg`,
      ];
    },
  },
};
</script>

<style scoped>
.thumbnail-library {
  margin-top: 2rem;
}

.thumbnail-library__item img{
  max-width: 100%;
}
</style>
