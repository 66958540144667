<template>
  <nav id="navBar"><a href="https://dinhtran.page/">About me</a></nav>
  <img id="appLogo" alt="Vue logo" src="./assets/logo.png" />
  <h1>Get Youtube Thumbnail</h1>
  <SearchBox
    id="searchBox"
    @value-changed="onTextBoxChange"
    :value="videoUrl"
  />
  <div style="margin-top: 0.8em">
    <button
      @click="onClearClick"
      class="btn btn-primary"
      style="padding: 0.6em 1.2em"
    >
      Clear
    </button>
    <a
      v-if="!!videoId"
      class="btn btn-primary"
      :href="videoUrl"
      target="_blank"
      rel="noopener"
      style="padding: 0.6em 1.2em; margin-left: 1em"
    >
      Open video
    </a>
  </div>
  <div class="log" v-if="!videoId && videoUrl">
    <div>
      Couldn't get the thumbnail, please check your url. A valid url should look
      like these:
    </div>
    <pre>
    https://www.youtube.com/watch?v=...
    https://youtu.be/...
    https://www.youtube.com/embed/...
    </pre>
  </div>
  <ThumbnailLibrary v-if="videoId" :video-id="videoId" />
</template>

<script>
import SearchBox from "./components/SearchBox.vue";
import ThumbnailLibrary from "./components/ThumbnailLibrary.vue";
import getVideoId from "./components/video-id-getter";
export default {
  methods: {
    onTextBoxChange(data) {
      this.$router.push({ query: { v: data } });
    },
    onClearClick() {
      this.$router.push({ query: { v: "" } });
    },
  },
  computed: {
    videoId() {
      return getVideoId(this.videoUrl);
    },
    videoUrl() {
      const url = this.$route.query.v || "";
      return url;
    },
  },
  components: {
    SearchBox,
    ThumbnailLibrary,
  },
};
</script>

<style src="./App.css">
</style>
