<template>
  <div class="form-group">
    <span>Youtube video url</span>
    <input
      v-bind="$attrs"
      class="form-field"
      @input="onInput"
      :value="value"
      placeholder="Enter youtube video url here"
      title="Enter youtube video url here"
    />
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  inheritAttrs: false,
  props: {
    value: { type: String, required: true },
  },
  emits: {
    "value-changed": null,
  },
  methods: {
    onInput(e) {
      this.$emit("value-changed", e.currentTarget.value);
    },
  },
};
</script>

<style scoped>
</style>
